(function() {
    angular.module('EntrakV5').controller('resetPwdController', resetPwdController);

    function resetPwdController($scope, $rootScope, $timeout, Service, Api, KEY, $state, $stateParams) {
        console.log('resetPwdController');

        var email = decodeURIComponent($stateParams.email);
        var confirm_code = $stateParams.code;

        $scope.isResetPwdPage = true;

        $scope.resetData = {
            pwd: "",
            confirmPwd: "",
            resetDone: false,
            loading: false
        }

        // $scope.isInvalidToken = false;

        // Api.validateResetPwdToken(token).then(function(){
        //     $scope.isResetPwdPage = true;
        // }, function(){
        //     if (forTest){
        //         $scope.isResetPwdPage = true;
        //     } else {
        //         $scope.isInvalidToken = true;
        //         $scope.redirectSecond = 15;
        //         runTimer();
        //     }
        // });

        // function runTimer(){
        //     $timeout(function(){
        //         if ($scope.redirectSecond){
        //             $scope.redirectSecond--;
        //             runTimer();
        //         } else {
        //             $state.go("login", { landlord: null });
        //         }
        //     }, 1000);
        // }

        $scope.showRules = false;
        $scope.isAllValid = false;
        $scope.rules = null;
        Service.formPost("auth/pwdpolicy", { email })
            .then(Service.parseJson)
            .then(res => {
              $scope.$apply(function(){
                $scope.showRules = true;
                $scope.rules = [];
                if (res.policies) {
                  res.policies.forEach(policy => {
                    if (policy.required) {
                      if (policy.name === "length") {
                        let lbl = policy.max && policy.max >= policy.min ? Service.translate("lbl.pwdMinMax", policy) : Service.translate("lbl.pwdMin", policy);
                        $scope.rules.push({ lbl, isValid: false, exp: new RegExp(policy.rule) });
                      } else if (policy.name === "at least one number") {
                        $scope.rules.push({ lbl: Service.translate("lbl.pwdNumber"), isValid: false, exp: new RegExp(policy.rule) });
                      } else if (policy.name === "at least one upper case") {
                        $scope.rules.push({ lbl: Service.translate("lbl.pwdUpperCase"), isValid: false, exp: new RegExp(policy.rule) });
                      } else if (policy.name === "at least one lower case") {
                        $scope.rules.push({ lbl: Service.translate("lbl.pwdLowerCase"), isValid: false, exp: new RegExp(policy.rule) });
                      } else if (policy.name === "at least one special char") {
                        $scope.rules.push({ lbl: Service.translate("lbl.pwdSpecialChar"), isValid: false, exp: new RegExp(policy.rule) });
                      } else {
                        
                      }
                    }
                  });
                }
              });
            })
            .catch(err => {
              $scope.$apply(function(){
                console.error("err", err);
                $scope.errMsg = Service.translate("error.generalResetPwdFail");
              });
            });

        $scope.handleValidation = function(){
          $scope.rules.forEach(r => {
            r.isValid = r.exp.test($scope.resetData.pwd);
          });
          $scope.isAllValid = $scope.rules.find(r => !r.isValid) == null;
        }

        $scope.passwordPlaceHolder = Service.translate('login.password');
        $scope.confirmPasswordPlaceHolder = Service.translate('login.confirmPwd');

        $scope.reset = function(){
          $scope.resetData.loading = true;
        	$scope.errMsg = null;
          Service.formPost("auth/forgotpwd_confirm", { password: $scope.resetData.pwd, confirm_code, email })
            .then(Service.parseJson)
            .then(res => {
              $scope.$apply(function(){
                if (res.errno) {
                  if (res.errno == 504 || res.errno == 505) {
                    $scope.errMsg = Service.translate("error.invalidLink");
                  } else if (res.errno == 502) {
                    $scope.errMsg = Service.translate("error.pwdFormatInvalid");
                  } else {
                    $scope.errMsg = Service.translate("error.generalResetPwdFail");
                  }
                  console.log("resetPwd error:", res.errno, res.errmsg);
                } else {
                  $scope.resetData.resetDone = true;
                }
                $scope.resetData.loading = false;
              });
            })
            .catch(err => {
              $scope.$apply(function(){
                console.error("err", err);
                $scope.errMsg = Service.translate("error.generalResetPwdFail");
                $scope.resetData.loading = false;
              });
            });
        }

        $scope.backToSignIn = function(){
            $state.go("login", { landlord: null });
        }

        $scope.$on('$destroy', function() {
            console.log("resetPwdController destroy");
        });
    }
})();
